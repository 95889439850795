import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostList from '../components/PostList';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css'

import TagBadge from '../components/TagBadge';

const CategoryTitle = styled.h1`
  margin-bottom: 0.2rem;
  font-size: 2.6rem;
`;


class BlogIndex extends Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;


    return (
      <Layout>
        <SEO title="Allar færslur" keywords={[`hjólreiðar`, `breiðablik`, `ic8`]} />      
        <main>      
        <h1>Færslur merktar með <TagBadge tag='draft' /></h1>
              <section>              
                <PostList posts={posts} />             
              </section>   
        </main>
      </Layout>
    );
}
}


export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "draft" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
         
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            tags
          }
        }
      }
    }
  }
`;
